import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"

import Layout from "../components/layout"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

const AboutPage = () => {
  const data = useStaticQuery(graphql`{
  banner: file(relativePath: {eq: "2xbanner5.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image1: file(relativePath: {eq: "2ximg-41.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  image2: file(relativePath: {eq: "2ximg-42.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  image3: file(relativePath: {eq: "2ximg-43.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout sitePage="about">
      <SEO title="关于我们" />
      <div className={`mainContent`}>
        <Header
          activePage="about"
          color={{ bg: "transparent", text: "#ffffff" }}
        />
        <div className="banner" data-bg-color="" data-color="#fff">
          <div className="img">
            <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
          </div>
        </div>
        <div
          className="point-bar js-viewport-animation"
          style={{ backgroundColor: "#caf5f3" }}
          data-bg-color="#9ae4da"
          data-color="#006572"
        >
          <div className="list js_anchor cn-point">
            <a href="#row01">
              <span>01</span>关于森唯
            </a>
            <a href="#row03">
              <span>02</span>森唯信念
            </a>
            <a href="#row04">
              <span>03</span>森唯团队
            </a>
            <div className="point"></div>
          </div>
        </div>
        <div
          id="row01"
          className="row01 js-viewport-animation"
          data-bg-color="#9ae4da"
          data-color="#006572"
        >
          <div className="wp1180">
            <h3 className="tit01" style={{ color: "#fff" }}>
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 368.53 49.1"
              >
                <g>
                  <g>
                    <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
                    <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
                    <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
                    <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
                    <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
                    <path d="M191.44,47.83,186.09,34.9H159.91l-5.54,12.93h-1.53l19.55-45.1h1.79L193,47.83ZM173.22,3.62l-12.8,30.07h25.22Z" />
                    <path d="M229.21,35.79a11.66,11.66,0,0,1-1.36,6,10.63,10.63,0,0,1-3.51,3.66,14.32,14.32,0,0,1-4.71,1.88,24.14,24.14,0,0,1-4.94.54H200.87V2.73h12.29a25.5,25.5,0,0,1,5.07.51A13.76,13.76,0,0,1,222.72,5a9.43,9.43,0,0,1,3.18,3.25,10,10,0,0,1,1.21,5.16,10.61,10.61,0,0,1-.76,4.2,9.4,9.4,0,0,1-2.07,3.06,11.11,11.11,0,0,1-3,2.07,16,16,0,0,1-3.38,1.18V24a15.08,15.08,0,0,1,3.69.92,12.7,12.7,0,0,1,3.67,2.13,11.4,11.4,0,0,1,2.8,3.57A11.16,11.16,0,0,1,229.21,35.79Zm-3.5-22.23A9,9,0,0,0,224.63,9,8.23,8.23,0,0,0,221.76,6a12.92,12.92,0,0,0-4.11-1.56A24,24,0,0,0,212.78,4H202.27V23.43H213.1a18.54,18.54,0,0,0,4.55-.57,13.22,13.22,0,0,0,4-1.75,9.44,9.44,0,0,0,2.9-3.06A8.67,8.67,0,0,0,225.71,13.56ZM227.81,36a10.29,10.29,0,0,0-1.24-5.22,10.8,10.8,0,0,0-3.15-3.51,13.35,13.35,0,0,0-4.21-1.94,17.56,17.56,0,0,0-4.46-.6H202.27V46.56h11.78a23.83,23.83,0,0,0,5.48-.61,12.6,12.6,0,0,0,4.37-1.88,9.07,9.07,0,0,0,2.86-3.28A10.26,10.26,0,0,0,227.81,36Z" />
                    <path d="M284.06,25.28a25.49,25.49,0,0,1-1.75,9.59,22.64,22.64,0,0,1-4.84,7.51,22,22,0,0,1-7.36,4.94,24.85,24.85,0,0,1-18.47,0,22,22,0,0,1-7.36-4.94,22.64,22.64,0,0,1-4.84-7.51,25.49,25.49,0,0,1-1.75-9.59,25.49,25.49,0,0,1,1.75-9.59,22.64,22.64,0,0,1,4.84-7.51,22,22,0,0,1,7.33-4.94,24.87,24.87,0,0,1,18.53,0,22,22,0,0,1,7.33,4.94,22.64,22.64,0,0,1,4.84,7.51A25.49,25.49,0,0,1,284.06,25.28Zm-1.4,0a24.23,24.23,0,0,0-1.59-8.82,21.72,21.72,0,0,0-4.49-7.17,21.1,21.1,0,0,0-6.91-4.81,23,23,0,0,0-17.59,0,21,21,0,0,0-6.91,4.81,21.72,21.72,0,0,0-4.49,7.17,24.23,24.23,0,0,0-1.59,8.82,24.65,24.65,0,0,0,1.56,8.82,21.57,21.57,0,0,0,4.43,7.17A20.57,20.57,0,0,0,252,46.08a23.58,23.58,0,0,0,17.83,0,20.57,20.57,0,0,0,6.88-4.81,21.57,21.57,0,0,0,4.43-7.17A24.65,24.65,0,0,0,282.66,25.28Z" />
                    <path d="M328.27,37.92a15.13,15.13,0,0,1-2.93,5.83A14,14,0,0,1,320,47.67a23.4,23.4,0,0,1-16.11,0,14,14,0,0,1-5.32-3.92,15.13,15.13,0,0,1-2.93-5.83,27.43,27.43,0,0,1-.9-7.16v-28h1.41V30.5a32.39,32.39,0,0,0,.54,5.7,14.86,14.86,0,0,0,2.23,5.61,13.24,13.24,0,0,0,4.77,4.3A16.92,16.92,0,0,0,312,47.83a17.13,17.13,0,0,0,8.13-1.66,13,13,0,0,0,4.81-4.23,15.13,15.13,0,0,0,2.29-5.61,31.51,31.51,0,0,0,.57-5.83V2.73h1.4v28A27.43,27.43,0,0,1,328.27,37.92Z" />
                    <path d="M353.18,4V47.83h-1.4V4H336.42V2.73h32.11V4Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <div className="text02 text02-cn">
              <p className="p-bold-cn">
                S&W
                森唯（北京）建筑工程有限公司，始创于2001年，专业从事设计施工一体化服务，致力于帮助客户通过空间再造提升品牌影响力，使环境对人产生积极的影响。
              </p>
              <br />
              <p>
                森唯拥有建筑工程装饰装修专业承包壹级资质、设计专项甲级资质及多项专业认证，集合国内外设计、机电及项目管理专才，在不同阶段均能提供专业支持，工期、质量、环境安全是森唯全效风险控制的核心内容。在办公领域，我们不仅擅长制定系统的环境解决方案，还能为客户创建更具人性化体验的未来办公模式，满足新型办公在绿色环保、人文关怀、科技智能等方面的多维度需要。森唯凭借十几年的实践经验及对品质的卓越追求，打造了众多丰富而有趣的空间，希尔顿酒店集团、皇冠假日酒店、大成律师事务所等均是我们服务过的客户。专业化、标准化以及对健康安全的高标准制定是森唯值得信赖的原因。
              </p>
              <br />
              <p>
                森唯创新求变着眼未来，致力打造环境空间的可持续性，在设计与实施中引入LEED、WELL等环保认证体系和健康工作理念，并与国际知名材料品牌达成战略合作、配建自有家具生产基地、选用进口生产线及无醛板材、制定标准化质量规范，为客户在品质、环保及成本控制方面提供切实的支持和保证。
                <br />
                客户的认可并愿意再次携手是我们成长的源动力，S&W带着自己的坚持，脚踏实地，愿与您共同创建高品质室内空间。
              </p>
              <div className="img">
                <GatsbyImage image={data.image1?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="row02"
          className="row02 js-viewport-animation"
          data-bg-color="#3e3a39"
          data-color="#fff"
        >
          <div className="wp1180">
            <h3 className="tit01" style={{ color: "#fff" }}>
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 485.94 49.53"
              >
                <g>
                  <g>
                    <path d="M28.56,35.53c0-9.08-27-3.23-27-20.5C1.51,5.39,10.46.85,19,.85c9.64,0,18.31,4.27,18.65,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,49.43.07,44.34,0,32.92H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,41,28.56,39.73,28.56,35.53Z" />
                    <path d="M75.42,48.4l-4.13-5.16C67.85,46.54,63.58,49,58.7,49c-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.09,6.88-12,.83-.62,1.65-1.11,2.48-1.72C50.65,18.4,49,15.58,49,12.2,49,5.32,54,.85,60.76.85c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12a66.38,66.38,0,0,1-5.23,6.4L86.77,48.4ZM58.28,27.48c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61a3,3,0,0,0-3.31-3.1A3.08,3.08,0,0,0,57.46,11.79Z" />
                    <path d="M135.15,48.42H122.9l-9.7-32.83-9.77,32.83H91.18L82.1,1.62H93.45L99,33.9l9.15-32.28h10.12l9.15,32.28,5.5-32.28h11.36Z" />
                    <path d="M151.06,11.46a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,11.46Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1.84Z" />
                    <path d="M152.71,9.17l-1.57-2.62H150V9.17h-.82V3h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,6.47l1.65,2.7ZM150,5.92h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
                    <path d="M190.22,37a11.93,11.93,0,0,1-1.16,5.33,12.1,12.1,0,0,1-3.08,4,13,13,0,0,1-4.54,2.45,18,18,0,0,1-5.46.83A19.93,19.93,0,0,1,168,48a16.76,16.76,0,0,1-6-4.2l1.12-1A16.31,16.31,0,0,0,169,46.85a18.35,18.35,0,0,0,6.92,1.36,15.52,15.52,0,0,0,4.9-.77,12.84,12.84,0,0,0,4.11-2.18,10.14,10.14,0,0,0,2.81-3.51,10.66,10.66,0,0,0,1-4.8,8.67,8.67,0,0,0-1.13-4.57,11,11,0,0,0-2.91-3.18A17.81,17.81,0,0,0,180.65,27c-1.52-.59-3.06-1.13-4.6-1.62-2-.62-3.72-1.27-5.33-1.95a16.3,16.3,0,0,1-4.18-2.52,10.21,10.21,0,0,1-2.71-3.57,12.1,12.1,0,0,1-1-5.07,11.14,11.14,0,0,1,4.3-9A14.4,14.4,0,0,1,171.78.83,18.21,18.21,0,0,1,177.3,0a17.16,17.16,0,0,1,6.79,1.36,13.34,13.34,0,0,1,5.27,3.87l-1.13,1a12.53,12.53,0,0,0-4.73-3.6,15,15,0,0,0-6.26-1.3,16.74,16.74,0,0,0-4.67.67,13.08,13.08,0,0,0-4.14,2,10.21,10.21,0,0,0-3,3.41,9.9,9.9,0,0,0-1.12,4.83,9.8,9.8,0,0,0,1,4.6A9.92,9.92,0,0,0,168,20.06a14.89,14.89,0,0,0,3.74,2.19c1.39.57,2.81,1.1,4.27,1.59q3,1,5.56,2a19.63,19.63,0,0,1,4.54,2.48,10.3,10.3,0,0,1,3,3.54A10.79,10.79,0,0,1,190.22,37Z" />
                    <path d="M212.53,2.65V48.21h-1.45V2.65h-16V1.32h33.37V2.65Z" />
                    <path d="M267,48.21l-5.56-13.45H234.25l-5.76,13.45H226.9L247.23,1.32h1.85l19.54,46.89Zm-18.94-46L234.78,33.51H261Z" />
                    <path d="M284.44,2.65V48.21H283V2.65H267V1.32H300.4V2.65Z" />
                    <path d="M308.61,48.21V1.32h1.46V48.21Z" />
                    <path d="M350.06,37a11.8,11.8,0,0,1-1.16,5.33,12.07,12.07,0,0,1-3.07,4,13.17,13.17,0,0,1-4.54,2.45,18.06,18.06,0,0,1-5.46.83,19.86,19.86,0,0,1-8-1.56,16.8,16.8,0,0,1-6-4.2l1.13-1a16.31,16.31,0,0,0,5.86,4.07,18.35,18.35,0,0,0,6.92,1.36,15.56,15.56,0,0,0,4.9-.77,13,13,0,0,0,4.11-2.18,10.25,10.25,0,0,0,2.81-3.51,10.79,10.79,0,0,0,1-4.8,8.67,8.67,0,0,0-1.13-4.57,11,11,0,0,0-2.91-3.18A17.81,17.81,0,0,0,340.5,27c-1.53-.59-3.06-1.13-4.61-1.62q-2.91-.93-5.33-1.95a16.41,16.41,0,0,1-4.17-2.52,10.09,10.09,0,0,1-2.71-3.57,12.1,12.1,0,0,1-1-5.07,11.4,11.4,0,0,1,1.15-5.2A11.53,11.53,0,0,1,327,3.21a14.34,14.34,0,0,1,4.6-2.38A18.32,18.32,0,0,1,337.15,0a17.19,17.19,0,0,1,6.79,1.36,13.31,13.31,0,0,1,5.26,3.87l-1.12,1a12.57,12.57,0,0,0-4.74-3.6,15,15,0,0,0-6.25-1.3,16.74,16.74,0,0,0-4.67.67,12.87,12.87,0,0,0-4.14,2,10.21,10.21,0,0,0-3,3.41,9.91,9.91,0,0,0-1.13,4.83,9.68,9.68,0,0,0,1,4.6,10,10,0,0,0,2.64,3.21,15.13,15.13,0,0,0,3.75,2.19c1.39.57,2.81,1.1,4.27,1.59,2,.66,3.84,1.33,5.56,2a19.84,19.84,0,0,1,4.54,2.48,10.41,10.41,0,0,1,3,3.54A10.79,10.79,0,0,1,350.06,37Z" />
                    <path d="M372.38,2.65V48.21h-1.46V2.65H355V1.32h33.38V2.65Z" />
                    <path d="M396.55,48.21V1.32H398V48.21Z" />
                    <path d="M442.93,47.78a25.58,25.58,0,0,1-9.24,1.75A24.57,24.57,0,0,1,424,47.68a22.5,22.5,0,0,1-12.48-13,27.5,27.5,0,0,1-1.76-9.93,27.51,27.51,0,0,1,1.76-9.94,22.43,22.43,0,0,1,12.51-13A24.71,24.71,0,0,1,433.69,0a25,25,0,0,1,8.51,1.49A18.13,18.13,0,0,1,449.26,6l-.93,1a15.87,15.87,0,0,0-3.15-2.54,20.63,20.63,0,0,0-3.71-1.83,21.56,21.56,0,0,0-3.93-1,23.3,23.3,0,0,0-13,1.49,21.12,21.12,0,0,0-7.08,5,22.64,22.64,0,0,0-4.57,7.45,25.69,25.69,0,0,0-1.62,9.18,26.11,26.11,0,0,0,1.59,9.17,22.38,22.38,0,0,0,4.53,7.45,21.26,21.26,0,0,0,7.09,5,22.6,22.6,0,0,0,9.23,1.83,23.48,23.48,0,0,0,8.65-1.69,17.24,17.24,0,0,0,7.05-5.07l1.06,1A18.12,18.12,0,0,1,442.93,47.78Z" />
                    <path d="M485.94,37a11.93,11.93,0,0,1-1.16,5.33,12.1,12.1,0,0,1-3.08,4,13,13,0,0,1-4.53,2.45,18.12,18.12,0,0,1-5.47.83,19.93,19.93,0,0,1-8-1.56,16.85,16.85,0,0,1-6-4.2l1.13-1a16.23,16.23,0,0,0,5.86,4.07,18.31,18.31,0,0,0,6.92,1.36,15.56,15.56,0,0,0,4.9-.77,12.9,12.9,0,0,0,4.1-2.18,10.16,10.16,0,0,0,2.82-3.51,10.79,10.79,0,0,0,1-4.8,8.67,8.67,0,0,0-1.12-4.57,10.91,10.91,0,0,0-2.92-3.18A17.81,17.81,0,0,0,476.37,27c-1.52-.59-3.06-1.13-4.6-1.62q-2.91-.93-5.33-1.95a16.41,16.41,0,0,1-4.17-2.52,10.24,10.24,0,0,1-2.72-3.57,12.1,12.1,0,0,1-1-5.07,11.11,11.11,0,0,1,4.31-9A14.24,14.24,0,0,1,467.5.83,18.28,18.28,0,0,1,473,0a17.23,17.23,0,0,1,6.79,1.36,13.31,13.31,0,0,1,5.26,3.87l-1.13,1a12.46,12.46,0,0,0-4.73-3.6A15,15,0,0,0,473,1.32a16.79,16.79,0,0,0-4.67.67,13.08,13.08,0,0,0-4.14,2,10.29,10.29,0,0,0-3,3.41,9.8,9.8,0,0,0-1.13,4.83,9.8,9.8,0,0,0,1,4.6,10,10,0,0,0,2.65,3.21,14.89,14.89,0,0,0,3.74,2.19c1.39.57,2.82,1.1,4.27,1.59,2,.66,3.84,1.33,5.57,2a19.56,19.56,0,0,1,4.53,2.48,10.44,10.44,0,0,1,3.05,3.54A10.79,10.79,0,0,1,485.94,37Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <ul className="count01 count01-cn">
              <li className="s1 wow fadeIn">
                <div className="count01-item">
                  <div className="val">2001</div>
                  <div className="lab">始创于2001年</div>
                </div>
              </li>
              <li className="s2 wow fadeIn" data-wow-delay=".2s">
                <div className="count01-item">
                  <div className="val">{new Date().getFullYear() - 2001}+</div>
                  <div className="lab">
                    {new Date().getFullYear() - 2001}年行业经验
                  </div>
                </div>
              </li>
              <li className="s3 wow fadeIn" data-wow-delay=".4s">
                <div className="count01-item">
                  <div className="val">9700</div>
                  <div className="lab">9700万注册资本额</div>
                </div>
              </li>
              <li className="s1 wow fadeIn" data-wow-delay=".6s">
                <div className="count01-item">
                  <div className="val">20000</div>
                  <div className="lab">工厂面积2万平米</div>
                </div>
              </li>
              <li className="s2 wow fadeIn" data-wow-delay=".8s">
                <div className="count01-item">
                  <div className="val">1+1</div>
                  <div className="lab">双甲级资质</div>
                </div>
              </li>
              <li className="s3 wow fadeIn" data-wow-delay="1s">
                <div className="count01-item">
                  <div className="val">100+</div>
                  <div className="lab">100万总施工平米</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="row03"
          className="row03 js-viewport-animation"
          data-bg-color="#9ae4da"
          data-color="#fff"
        >
          <div className="wp1180">
            <h3 className="tit01" style={{ color: "#fff" }}>
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 393.56 49.58"
              >
                <g>
                  <g>
                    <path d="M192.86,35.69a12,12,0,0,1-1.43,6.19,11,11,0,0,1-3.64,3.81,14.72,14.72,0,0,1-4.9,2,24.71,24.71,0,0,1-5.13.57H163.39V1.32h12.78a26.76,26.76,0,0,1,5.27.53,14.4,14.4,0,0,1,4.66,1.79A9.65,9.65,0,0,1,189.41,7a10.35,10.35,0,0,1,1.26,5.36,11,11,0,0,1-.79,4.37,9.76,9.76,0,0,1-2.15,3.18,11.77,11.77,0,0,1-3.08,2.15,16.12,16.12,0,0,1-3.51,1.23v.13a16.09,16.09,0,0,1,3.84,1,13.1,13.1,0,0,1,3.81,2.22,11.8,11.8,0,0,1,2.91,3.71A11.61,11.61,0,0,1,192.86,35.69Zm-3.64-23.11a9.3,9.3,0,0,0-1.13-4.77,8.51,8.51,0,0,0-3-3,13.35,13.35,0,0,0-4.27-1.62,24.49,24.49,0,0,0-5.07-.5H164.85V22.84H176.1a19.12,19.12,0,0,0,4.74-.59,13.51,13.51,0,0,0,4.2-1.82,9.78,9.78,0,0,0,3-3.18A9,9,0,0,0,189.22,12.58Zm2.18,23.31a10.75,10.75,0,0,0-1.29-5.43,11.09,11.09,0,0,0-3.28-3.64,13.52,13.52,0,0,0-4.37-2,18.08,18.08,0,0,0-4.63-.63h-13V46.88H177.1a24.49,24.49,0,0,0,5.69-.63,13.14,13.14,0,0,0,4.54-2,9.44,9.44,0,0,0,3-3.41A10.64,10.64,0,0,0,191.4,35.89Z" />
                    <path d="M203.92,48.21V1.32h27.34V2.65H205.37V23.24h24.37v1.33H205.37V46.88h26.95v1.33Z" />
                    <path d="M243,48.21V1.32h1.46V46.88h23.31v1.33Z" />
                    <path d="M276,48.21V1.32h1.46V48.21Z" />
                    <path d="M291.45,48.21V1.32H318.8V2.65H292.91V23.24h24.37v1.33H292.91V46.88h26.95v1.33Z" />
                    <path d="M332,2.65V23.24h23.5v1.33H332V48.21h-1.46V1.32h26.75V2.65Z" />
                    <path d="M393.56,37a11.93,11.93,0,0,1-1.16,5.33,12.1,12.1,0,0,1-3.08,4,13,13,0,0,1-4.53,2.45,18.12,18.12,0,0,1-5.47.83,19.93,19.93,0,0,1-8-1.56,16.85,16.85,0,0,1-6-4.2l1.13-1a16.23,16.23,0,0,0,5.86,4.07,18.31,18.31,0,0,0,6.92,1.36,15.56,15.56,0,0,0,4.9-.77,12.9,12.9,0,0,0,4.1-2.18,10.16,10.16,0,0,0,2.82-3.51,10.79,10.79,0,0,0,1-4.8A8.67,8.67,0,0,0,391,32.38a10.91,10.91,0,0,0-2.92-3.18A17.81,17.81,0,0,0,384,27c-1.52-.59-3.06-1.13-4.6-1.62q-2.91-.93-5.33-1.95a16.41,16.41,0,0,1-4.17-2.52,10.24,10.24,0,0,1-2.72-3.57,12.1,12.1,0,0,1-1-5.07,11.11,11.11,0,0,1,4.31-9,14.24,14.24,0,0,1,4.6-2.38A18.28,18.28,0,0,1,380.65,0a17.23,17.23,0,0,1,6.79,1.36,13.31,13.31,0,0,1,5.26,3.87l-1.13,1a12.46,12.46,0,0,0-4.73-3.6,15,15,0,0,0-6.26-1.3,16.79,16.79,0,0,0-4.67.67,13.08,13.08,0,0,0-4.14,2,10.29,10.29,0,0,0-3,3.41,9.8,9.8,0,0,0-1.13,4.83,9.8,9.8,0,0,0,1,4.6,10,10,0,0,0,2.65,3.21,14.89,14.89,0,0,0,3.74,2.19c1.39.57,2.82,1.1,4.27,1.59,2,.66,3.84,1.33,5.57,2a19.56,19.56,0,0,1,4.53,2.48,10.44,10.44,0,0,1,3.05,3.54A10.79,10.79,0,0,1,393.56,37Z" />
                    <path d="M28.56,35.67c0-9.08-27-3.23-27-20.5C1.51,5.54,10.46,1,19,1,28.63,1,37.3,5.26,37.64,15.93h-10c-.55-4.89-3.37-6.47-8.53-6.47-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.17,0,11-9.84,15.07-19.4,15.07C9.5,49.58.07,44.48,0,33.06H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,41.18,28.56,39.87,28.56,35.67Z" />
                    <path d="M75.42,48.54l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.1,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.67C49,5.47,54,1,60.76,1c6.4,0,11.7,4.2,11.7,10.88,0,4.88-3.37,7.91-6.95,10.59l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A65.18,65.18,0,0,1,77.41,37l9.36,11.56ZM58.28,27.62c-2.13,1.45-6.05,4.13-6.05,7a6.38,6.38,0,0,0,6.12,6.13c2.75,0,5.51-2.27,7.37-4.06Zm-.82-15.69c0,2,1.58,3.38,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,9,3.08,3.08,0,0,0,57.46,11.93Z" />
                    <path d="M135.15,48.56H122.9l-9.7-32.82-9.77,32.82H91.18L82.1,1.77H93.45L99,34l9.15-32.27h10.12L127.38,34l5.5-32.27h11.36Z" />
                    <path d="M151.06,11.61a5.31,5.31,0,1,1,5.31-5.31A5.31,5.31,0,0,1,151.06,11.61Zm0-9.63a4.32,4.32,0,1,0,4.33,4.32A4.33,4.33,0,0,0,151.06,2Z" />
                    <path d="M152.71,9.31,151.14,6.7H150V9.31h-.82V3.15h2c1.32,0,2.26.44,2.26,1.73A1.69,1.69,0,0,1,152,6.61l1.65,2.7ZM150,6.07h1.08c1,0,1.57-.39,1.57-1.19s-.56-1.1-1.57-1.1H150Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <div className="pic01 pic01-cn">
              <div className="pic wow fadeIn">
                <GatsbyImage image={data.image2?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="item wow fadeIn" data-wow-delay=".2s">
                  <div className="lab bold">愿景</div>
                  <div className="val">成为值得信赖的建筑装饰服务商.</div>
                </div>
                <div className="item wow fadeIn" data-wow-delay=".4s">
                  <div className="lab bold">使命</div>
                  <div className="val">致力于人与环境的和谐共生.</div>
                </div>
                <div className="item wow fadeIn" data-wow-delay=".6s">
                  <div className="lab bold">核心价值观</div>
                  <div className="val">诚信负责 卓越品质 协作创新.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="row04"
          className="row04 js-viewport-animation"
          data-bg-color="#fff"
          data-color="#3e3a39"
        >
          <div className="wp1180">
            <h3 className="tit01">
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 369.13 49.53"
              >
                <g>
                  <g>
                    <path d="M28.56,35.53c0-9.08-27-3.23-27-20.5C1.51,5.39,10.46.85,19,.85c9.64,0,18.31,4.27,18.65,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,49.43.07,44.34,0,32.92H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,41,28.56,39.73,28.56,35.53Z" />
                    <path d="M75.42,48.4l-4.13-5.16C67.85,46.54,63.58,49,58.7,49c-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72C50.65,18.4,49,15.58,49,12.21,49,5.32,54,.85,60.76.85c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12a65.18,65.18,0,0,1-5.23,6.4L86.77,48.4ZM58.28,27.48c-2.13,1.45-6.05,4.13-6.05,7a6.38,6.38,0,0,0,6.12,6.13c2.75,0,5.51-2.28,7.37-4.06Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61a3,3,0,0,0-3.31-3.1A3.08,3.08,0,0,0,57.46,11.79Z" />
                    <path d="M135.15,48.42H122.9l-9.7-32.83-9.77,32.83H91.18L82.1,1.63H93.45L99,33.9l9.15-32.27h10.12l9.15,32.27,5.5-32.27h11.36Z" />
                    <path d="M151.06,11.46a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,11.46Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1.84Z" />
                    <path d="M152.71,9.17l-1.57-2.61H150V9.17h-.82V3h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,6.47l1.65,2.7ZM150,5.92h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.1-1.57-1.1H150Z" />
                    <path d="M175.85,2.65V48.21h-1.46V2.65h-16V1.32h33.38V2.65Z" />
                    <path d="M200,48.21V1.32h27.34V2.65H201.47V23.24h24.37v1.33H201.47V46.88h26.95v1.33Z" />
                    <path d="M273.38,48.21l-5.56-13.45H240.61l-5.76,13.45h-1.59L253.59,1.32h1.85L275,48.21Zm-18.93-46L241.14,33.51h26.22Z" />
                    <path d="M327.68,48.21V3h-.26L306.69,48.21H305.5L284.84,3h-.2V48.21h-1.46V1.32h2.59l20.39,44.77,20.4-44.77h2.58V48.21Z" />
                    <path d="M369.13,37A11.8,11.8,0,0,1,368,42.28a12.07,12.07,0,0,1-3.07,4,13,13,0,0,1-4.54,2.45,18.06,18.06,0,0,1-5.46.83,20,20,0,0,1-8-1.55,16.82,16.82,0,0,1-6-4.21l1.12-1a16.31,16.31,0,0,0,5.86,4.07,18.35,18.35,0,0,0,6.92,1.36,15.56,15.56,0,0,0,4.9-.77,13,13,0,0,0,4.11-2.18,10.14,10.14,0,0,0,2.81-3.51,10.79,10.79,0,0,0,1-4.8,8.67,8.67,0,0,0-1.13-4.57,11.28,11.28,0,0,0-2.91-3.18A17.81,17.81,0,0,0,359.57,27c-1.53-.59-3.06-1.13-4.61-1.62q-2.91-.93-5.33-1.95a16.13,16.13,0,0,1-4.17-2.52,10.09,10.09,0,0,1-2.71-3.57,12.1,12.1,0,0,1-1-5.07,11.4,11.4,0,0,1,1.15-5.2,11.53,11.53,0,0,1,3.15-3.84,14.34,14.34,0,0,1,4.6-2.38A18.32,18.32,0,0,1,356.22,0,17.19,17.19,0,0,1,363,1.36a13.31,13.31,0,0,1,5.26,3.87l-1.12,1a12.57,12.57,0,0,0-4.74-3.6,15,15,0,0,0-6.25-1.3,16.74,16.74,0,0,0-4.67.67,12.87,12.87,0,0,0-4.14,2,10.21,10.21,0,0,0-3,3.41,9.91,9.91,0,0,0-1.13,4.83,9.68,9.68,0,0,0,1,4.6,10,10,0,0,0,2.64,3.21,15.13,15.13,0,0,0,3.75,2.19c1.39.57,2.81,1.1,4.27,1.59,2,.66,3.84,1.33,5.56,2A19.84,19.84,0,0,1,365,28.34a10.41,10.41,0,0,1,3,3.54A10.79,10.79,0,0,1,369.13,37Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <div className="pic02 pic02-cn">
              <div className="pic wow fadeIn">
                <GatsbyImage image={data.image3?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt wow fadeIn" data-wow-delay=".2s">
                <div className="tit tit-cn">
                  设计 ‧ 工程 ‧ 商务
                  <br />
                  品控 ‧ 机电 ‧ 工厂
                  <br />
                </div>
                <div className="desc js-text-animation">
                  各专业配套齐全，在不同阶段均能提供专业支持。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage
